<template>
<div>
  <amplify-authenticator id="ampAuth" :style="amplifyStyle">
    <amplify-sign-in 
      :hide-sign-up="true"
      slot="sign-in"
      header-text="Sign in to your account"      
      >
    </amplify-sign-in>
    <div id="myDiv" :style="cssProps">
      <notifications></notifications>
      <router-view :key="$route.fullPath"></router-view>
    </div>  
  </amplify-authenticator>
  <img :src="polarLogo" style="display: none">
</div>
</template>

<script>
  import { mapState } from 'vuex'
  // The <img> with display: none is here just to make the loading faster in attemptLogoInjection()
  import polarLogo from "./assets/img/logo.png"

  export default {
    data() {
      return {
        color: '2px solid red;',
        amplifyStyle: {
          '--amplify-primary-color': "#003366",
          '--amplify-primary-tint': "#0e6ac7",
          '--amplify-primary-shade': "#00478f",
        },
        polarLogo: polarLogo
      }
    },
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      },
      computeGradientSecondaryColor(hexColorString) {
        /*
        TODO compute a color that is a "darker version" of the input one, use it to make a gradient
        Tip: decompose the color in rgb components and compute the differences instead of computing the difference of the hex value
        Cioè: prendi il colore base azzurro e la sua versione "scura", scomponili in rgb, calcola i 3 delta, quelli sono i delta che devi applicare al colore custom per fare il gradiente
        console.log(hexColorString)
        console.log("computing gradient")
        var delta = 927027;
        var colorValue = parseInt(hexColorString.replace(/^#/, ''), 16);
        console.log(colorValue)
        var secondaryColor = colorValue - delta;
        console.log(secondaryColor)
        var result = "#"+secondaryColor.toString(16)
        console.log(result)
        return result 
        */
      },
      attemptLogoInjection() {
        // Thanks to aws amplify for this abomination
        // A slot for the logo is not provided in the vue2 version
        // @hook:mounted won't fire on amplify-sign-in nor any other amplify components
        // Slotting the amplify-sign-in with a custom component and using his mounted() wouldn't work either
        // At the time mounted() is called, the shadowRoot of amplify-sign-in is still null
        console.log("attemptLogoInjection")
        try {
          var authenticator = document.querySelector("amplify-authenticator")
          var signIn = authenticator.querySelector('amplify-sign-in');
          var formSection = signIn.shadowRoot.querySelector('amplify-form-section');
          var innerSection = formSection.querySelector('.section')
          var imgDiv = innerSection.querySelector('div')
          let newElement = document.createElement('img');
          //newElement.src = "https://polar-electronics.com/wp-content/uploads/2021/04/LOGO-POLAR-senza-ombra-min.png"
          newElement.src = polarLogo
          newElement.style = "  display: block; margin-left: auto; margin-right: auto; width: 50%;"
          imgDiv.insertBefore(newElement, imgDiv.firstChild);
        } catch (error) {
          setTimeout(this.attemptLogoInjection, 10);
        }
      }
    },
    mounted() {
      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen);
      this.attemptLogoInjection();
    },
    computed: {
      ...mapState([
        'loggedUserEmail',
        'tempMainColor',
        'tempTextColor'
      ]),
      cssProps() {
        var style = {
          '--user-main-color': this.tempMainColor,
          '--user-gradient-secondary-color': this.tempMainColor,
          '--user-text-color': this.tempTextColor,
        }
        return style
      }
    }
  };
</script>

<style lang="scss">

#root-div {
  background-image: url(https://www.polar-electronics.com/wp-content/uploads/2021/05/1964-min.jpg);
}

.sidebar[data="vue"], .off-canvas-sidebar[data="vue"] {
	background: var(--user-main-color);
	background: -webkit-gradient(linear, left bottom, left top, from(var(--user-gradient-secondary-color)), to(var(--user-main-color)));
  background: linear-gradient(0deg, var(--user-gradient-secondary-color) 0%, var(--user-main-color) 100%) !important;
}

.main-panel {
	border-top: 2px solid var(--user-main-color) !important;
}

.badge-primary {
	color: rgb(232, 230, 227);
	background-color: var(--user-main-color) !important;
}

a.nav-link > p {
  color: var(--user-text-color) !important;
}

a.nav-link > i {
  color: var(--user-text-color) !important;
}

.btn-primary-nogradient {
	background-color: var(--user-main-color) !important;
	background-image: linear-gradient(to left bottom, var(--user-main-color), var(--user-main-color), var(--user-main-color)) !important;
	color: var(--user-text-color) !important;
	box-shadow: none;
}

.btn-danger-nogradient {
	color: var(--user-text-color) !important;
}

.btn-primary.btn-simple {
	color: var(--user-main-color) !important;
	border-color: var(--user-main-color) !important;
	background-color: transparent;
	background-image: none;
}

.input-group-focus .form-control {
  border-color: var(--user-main-color) !important;
}

.input-group-focus .input-group-prepend .input-group-text {
  border-color: var(--user-main-color) !important;
}

.form-control:focus {
  border-color: var(--user-main-color) !important;
}

.page-item.active .page-link {
	color: rgb(232, 230, 227);
	background-color: var(--user-main-color) !important;
	border-color: rgb(37, 101, 141);
}

li.badge, span.badge {
	background-color: var(--user-main-color) !important;
}

.custom-range::-webkit-slider-thumb {
  background-color: var(--user-main-color) !important;
}

.custom-range::-moz-range-thumb {
  background-color: var(--user-main-color) !important;
}

.custom-range::-ms-thumb {
  background-color: var(--user-main-color) !important;
}

input.input-error {
	border-color: #ec250d !important;
	color: #ec250d !important;
	/* background-color: rgba(222, 222, 222, 0.1); */
}

input.input-error:focus {
	border-color: #ec250d !important;
	color: #ec250d !important;
	/* background-color: rgba(222, 222, 222, 0.1); */
}

label.input-error {
  color: #ec250d !important;
}

div.input-error {
  border-color: #ec250d !important;
}

.verte__menu {
  background-color: #242729 !important;
}

.verte__input {
  background-color: #242729 !important;
  color: white;
}

.white-content div.verte__menu {
  background-color: white !important;
}

.white-content  .verte__input {
  background-color: white !important;
  color: black;
}

div.button-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

div.button-container {
  padding-right: 15px
}

</style>
