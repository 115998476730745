/** InfluxDB v2 URL */
const url = process.env['INFLUX_URL'] || 'https://eu-central-1-1.aws.cloud2.influxdata.com'
/** InfluxDB authorization token */
const token = process.env['INFLUX_TOKEN'] || 'x0T_F0muBZPJMUd1w4tVwhTgY8l_6sPAs6dX4exwDgfyE375t-FX_KE032HrzLLWjsjBtMcvCdvELNFswHGLBw=='
/** Organization within InfluxDB  */
const org = process.env['INFLUX_ORG'] || 'bms.cloud.polar@gmail.com'
/** InfluxDB bucket used in examples  */
const bucket = 'test'
const adminBucket = 'test'
/** Admin password  */
const password = 'montini'
/** Config file for bms alias */
const aliasFile = '/alias.yaml'
/** CB presence lookback timespan in recharge room */
const presenceLookbackTime = '10m'

const voltageEnum = {
  24 : "24 V", 
  36 : "36 V", 
  48 : "48 V",
  64 : "64 V", 
  72 : "72 V",
  80 : "80 V",
  96 : "96 V",
  120 : "120 V"
}

const batteryTechEnum = {
  0 : "WET",
  1 : "WET DEEP", 
  2 : "GEL",
  3 : "AGM1",
  4 : "AGM2",
  5 : "LIPOLY",
  6 : "LIFEPO",
}

module.exports = {
  url,
  token,
  org,
  bucket,
  adminBucket,
  password,
  aliasFile,
  presenceLookbackTime,
  voltageEnum,
  batteryTechEnum
}