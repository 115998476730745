<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/recharge-room" :name="$t('sidebar.rechargeRoom')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/charge-graph" :name="$t('sidebar.chargeGraph')" icon="tim-icons icon-sound-wave"/>
        <sidebar-link to="/charge-table" :name="$t('sidebar.chargeTable')" icon="fas fa-list-ul"/>
        <sidebar-link to="/global-recap" :name="$t('sidebar.globalRecap')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/daily-recap" :name="$t('sidebar.dailyRecap')" icon="tim-icons icon-calendar-60"/>  
        <sidebar-link v-if="canConfigBms" to="/bms-panel" :name="$t('sidebar.bmsPanel')" icon="tim-icons icon-settings"/>
        <sidebar-link v-if="isAdmin || (loggedUser && loggedUser.canCreateUsers)" to="/users-panel" :name="$t('sidebar.usersPanel')" icon="tim-icons icon-badge"/>
        <sidebar-link to="/customers-panel" :name="$t('sidebar.customersPanel')" icon="tim-icons icon-tag"/>   
        <sidebar-link to="/alerts-panel" :name="$t('sidebar.alertsPanel')" icon="far fa-bell"/>    
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>
<!--
      <content-footer></content-footer>
-->
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import { mapState } from 'vuex'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  computed: {
    ...mapState([
      'isAdmin',
      'canConfigBms',
      'loggedUser'
    ])
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
