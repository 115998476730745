import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import store from "@/main.js"

// These components are lazy-loaded. webpackChunkName configures the name of the corresponding chunk produced by webpack
const RechargeRoom = () => import(/* webpackChunkName: "dashboard" */"@/pages/RechargeRoom.vue");
const ChargeGraph = () => import(/* webpackChunkName: "dashboard" */"@/pages/ChargeGraph.vue");
const ChargeTable = () => import(/* webpackChunkName: "dashboard" */"@/pages/ChargeTable.vue");
const GlobalRecap = () => import(/* webpackChunkName: "dashboard" */"@/pages/GlobalRecap.vue");
const DailyRecap = () => import(/* webpackChunkName: "dashboard" */"@/pages/DailyRecap.vue");
const UsersPanel = () => import(/* webpackChunkName: "dashboard" */"@/pages/UsersPanel.vue");
const BmsPanel = () => import(/* webpackChunkName: "dashboard" */"@/pages/BmsPanel.vue");
const EditUser = () => import(/* webpackChunkName: "dashboard" */"@/pages/EditUser.vue");
const EditBms = () => import(/* webpackChunkName: "dashboard" */"@/pages/EditBms.vue");
const CustomersPanel = () => import(/* webpackChunkName: "dashboard" */"@/pages/CustomersPanel.vue");
const EditRenter = () => import(/* webpackChunkName: "dashboard" */"@/pages/EditRenter.vue");
const AlertsPanel = () => import(/* webpackChunkName: "dashboard" */"@/pages/AlertsPanel.vue");
const EditAlert = () => import(/* webpackChunkName: "dashboard" */"@/pages/EditAlert.vue");
const UserCustomization = () => import(/* webpackChunkName: "dashboard" */"@/pages/UserCustomization.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/recharge-room",
    children: [
      {
        path: "recharge-room",
        name: "recharge-room",
        component: RechargeRoom
      },
      {
        path: "charge-graph",
        name: "charge-graph",
        component: ChargeGraph
      },
      {
        path: "charge-table",
        name: "charge-table",
        component: ChargeTable
      },
      {
        path: "global-recap",
        name: "global-recap",
        component: GlobalRecap
      },
      {
        path: "daily-recap",
        name: "daily-recap",
        component: DailyRecap
      },
      {
        path: "users-panel",
        name: "users-panel",
        component: UsersPanel,
        beforeEnter: (to, from, next) => {
          if(store.state.isAdmin || (store.state.loggedUser && store.state.loggedUser.canCreateUsers))
            next()
          else
            next({
              path: '/recharge-room'
            })
        }
      },
      {
        path: "edit-user",
        name: "edit-user",
        component: EditUser,
        beforeEnter: (to, from, next) => {
          if(store.state.isAdmin || (store.state.loggedUser && store.state.loggedUser.canCreateUsers))
            next()
          else
            next({
              path: '/recharge-room'
            })
        }
      },
      {
        path: "bms-panel",
        name: "bms-panel",
        component: BmsPanel
      },
      {
        path: "edit-bms",
        name: "edit-bms",
        component: EditBms
      },
      {
        path: "customers-panel",
        name: "customers-panel",
        component: CustomersPanel
      },
      {
        path: "edit-renter",
        name: "edit-customer",
        component: EditRenter
      },
      {
        path: "alerts-panel",
        name: "alerts-panel",
        component: AlertsPanel
      },
      {
        path: "edit-alert",
        name: "edit-alert",
        component: EditAlert
      },
      {
        path: "user-customization",
        name: "user-customization",
        component: UserCustomization,
        meta: { 
          afterLeave (to, from, next) {
            console.log("leaving customization");
            store.commit('undoCustomization')
          },
      },
      }
    ]
  },
  { path: "*", redirect: "/recharge-room" },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
