import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/main.js"

import { createRouter, createWebHistory } from 'vue-router'

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.afterEach((to, from) => {
  // TODO move the commit in the meta of recharge-room route 
  store.commit('clearTimerHandlers')
  // Hack to perform per-route afterLeave() hooks https://github.com/vuejs/vue-router/issues/1195
  if(from.meta && from.meta.afterLeave)
    from.meta.afterLeave(to, from);
})

export default router;
