/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import router from "./router/index";
import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import VCalendar from 'v-calendar'
import Verte from 'verte';
import 'verte/dist/verte.css';
import Vuex from 'vuex'
import { DropdownPlugin, ModalPlugin, TablePlugin, PaginationPlugin, FormTagsPlugin, FormSelectPlugin } from 'bootstrap-vue'
import VueNumerals from 'vue-numerals';
import { InfluxDB } from '@influxdata/influxdb-client'
import { url, token, org, aliasFile } from '@/config/env'
import colors from '@/assets/sass/colors.scss'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, integer, double, min_value, max_value, between, email } from 'vee-validate/dist/rules';

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

const logEnabled = false

function conditionalExecution(functionToExecute, condition) {
  return function (...args) {
    if (condition) { // Assumi che 'flagGlobale' sia una variabile globale
      return functionToExecute(...args);
    } else {
      return undefined;
    }
  };
}

console.log = conditionalExecution(console.log, logEnabled)
console.info = conditionalExecution(console.info, logEnabled)
console.warn = conditionalExecution(console.warn, logEnabled)
console.error = conditionalExecution(console.error, logEnabled)

i18n.locale = 'it'

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('integer', {
  ...integer,
  message: 'Must be an integer'
});

extend('double', {
  ...double,
  message: 'Must be a number'
});

extend('between', {
  ...between,
  params: ['min', 'max'],
  message: 'Must be between {min} and {max}'
});

extend('listNotEmpty', {
  ...required,
  message: 'Choose at least one element'
});

extend('email', {
  ...email,
  message: 'Must be a valid address'
});

extend('monitorNotInArray', {
  validate(value ,{ monitorList }) {
    return !Array.isArray(monitorList) || (!monitorList.includes(value.trim()))
  },
  params: ['monitorList'],
  message: 'Another monitor with this name already exists'
});


import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import axios from 'axios'
import VueAxios from 'vue-axios'

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(BlackDashboard)
Vue.use(DropdownPlugin)
Vue.use(ModalPlugin)
Vue.use(PaginationPlugin)
Vue.use(FormTagsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(TablePlugin)
Vue.use(VueNumerals)
Vue.use(VueRouter)
Vue.use(RouterPrefetch)
Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.use(Verte)
//Vue.use(ValidationProvider);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});


if (localStorage.getItem("users") === null) {
  var defaultUser = [{"username": "test", "email": "test@test.com", "password": "password"}]
  localStorage.setItem("users", JSON.stringify(defaultUser))
}

if (localStorage.getItem("renters") === null) {
  var defaultRenter = [{"name": "noleggiatore1", "address": "via delle albicocche"}]
  localStorage.setItem("renters", JSON.stringify(defaultRenter))
}

const store = new Vuex.Store({
  state: {
    loggedUserEmail: undefined,
    loggedUser: {},
    isAdmin: false,
    isLvl1User: false,
    canConfigBms: false,
    loggedUserPreferences: {},
    alias: {},
    users: [],
    bmss: [],
    renters: [],
    monitors: [],
    timerHandlers: [],
    logged: false,
    whiteTheme: true,
    calendarColor: colors.mainColor ? hueToColor(hexToHsl(colors.mainColor)[0]) : 'green',
    logo: 'loading',
    tempLogo: 'loading',
    mainColor: '#FFFFFF',
    tempMainColor: '#FFFFFF',
    tempTextColor: '#000000'
  },
  getters: {

  },
  mutations: {
    setAlias(state, newAlias) {
      state.alias = newAlias
    },
    userLogin(state, user) {
      state.loggedUserEmail = user.email
      if(user["custom:admin"] && user["custom:admin"] == '1')
        state.isAdmin = true;
      else
        state.isAdmin = false;
    },
    userLogout(state) {
      // TODO crea un valore di default "stato base" che contiene tutti i valori base e lo ripristini all'uscita, è più facile da manutenere. 
      // La spread syntax può tornare utile
      state.isAdmin = false;
      state.isLvl1User = false,
      state.canConfigBms = false,
      state.loggedUserPreferences = {},
      state.loggedUserEmail = '';
      state.loggedUser = {};
      state.users = [];
      state.bmss = [];
      state.renters = [];
      state.logo = 'loading',
      state.tempLogo = 'loading',
      state.mainColor = '#FFFFFF',
      state.tempMainColor = '#FFFFFF'
      state.tempTextColor = '#000000'
    },
    setLoggedUser(state, newLoggedUser) {
      state.loggedUser = newLoggedUser
      if (newLoggedUser.canCreateUsers) state.isLvl1User = true
      if (newLoggedUser.canConfigBms) state.canConfigBms = true
    },
    setLoggedUserPreferences(state, newLoggedUserPreferences) {
      state.loggedUserPreferences = newLoggedUserPreferences
    },
    setUsers(state, newUsers) {
      state.users = newUsers
    },
    setBMSs(state, newBMSs) {
      state.bmss = newBMSs
    },
    setRenters(state, newRenters) {
      state.renters = newRenters
    },
    setMonitors(state, newMonitors) {
      state.monitors = newMonitors
    },
    setTempLogo(state, newLogo) {
      state.tempLogo = newLogo
    },
    setTempColor(state, newColor) {
      state.tempMainColor = newColor
      var rgb = hexToRgb(newColor)
      if ((rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114) >= 156) {
        state.tempTextColor = '#000000'
      }
      else {
        state.tempTextColor = '#FFFFFF'
      }
    },
    undoCustomization(state) {
      state.tempLogo = state.logo
      state.tempMainColor = state.mainColor
      var rgb = hexToRgb(state.mainColor)
      if ((rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114) >= 156) {
        state.tempTextColor = '#000000'
      }
      else {
        state.tempTextColor = '#FFFFFF'
      }
    },
    saveCustomization(state) {
      state.logo = state.tempLogo
      state.mainColor = state.tempMainColor
      state.calendarColor = hueToColor(hexToHsl(state.mainColor)[0])
    },
    addTimerHandler(state, newTimerHandler) {
      state.timerHandlers.push(newTimerHandler)
    },
    clearTimerHandlers(state) {
      state.timerHandlers.forEach(t => clearInterval(t))
      state.timerHandlers = []
    },
    toggleWhiteTheme(state, whiteTheme) {
      state.whiteTheme = whiteTheme
    },
    login(state) {
      state.logged = true
    },
    logout(state) {
      state.logged = false
    }
  },
  actions: {
    async updateUsers({commit, state}) {
      let response = await this._vm.axios.get('https://5e80bb0mtk.execute-api.eu-central-1.amazonaws.com/default/listBmsUsers')
      let usersList = response.data.Items;
      if(!state.isAdmin) {
        usersList = usersList.filter(user => user.email == state.loggedUserEmail || ( user.creator && user.creator == state.loggedUserEmail))
      } 
      commit('setUsers', usersList)
      let loggedUser = state.users.find(user => user.email == state.loggedUserEmail)
      commit('setLoggedUser', loggedUser)
      if(loggedUser.preferences)
        commit('setLoggedUserPreferences', loggedUser.preferences)
    },
    async updateBMSs({commit, state}) {
      let response = await this._vm.axios.get('https://8jd3b0rjvl.execute-api.eu-central-1.amazonaws.com/default/listBms')
      let bmsList = response.data.Items;
      console.log("Fetch bms")
      console.log(state)
      // TODO - Fix this filter: If the user is a lvl1 user, allow ONLY bms which owner is him or an user created by him (bms.owner.creator === loggedUser)
      if(!state.isAdmin) {
        if(state.isLvl1User) {
          //bmsList = bmsList.filter(bms => !bms.owner || bms.owner == state.loggedUserEmail)
          var newBmsList = []
          bmsList.forEach(bms => {
            if(!bms.owner || bms.owner == state.loggedUserEmail) {
              newBmsList.push(bms)
            }
            var fullOwner = state.users.find(user => user.email == bms.owner)
            if(fullOwner && fullOwner.creator == state.loggedUserEmail) {
              newBmsList.push(bms)
            }
          })
          bmsList = newBmsList
        } else {
          bmsList = bmsList.filter(bms => bms.owner == state.loggedUserEmail)
        }  
      }
      console.log(bmsList)
      commit('setBMSs', bmsList)
    },
    async updateRenters({commit, state}) {
      let response = await this._vm.axios.get('https://uvcn18cyw9.execute-api.eu-central-1.amazonaws.com/default/listRenters')
      let renterList = response.data.Items;
      console.log("Fetch renters")
      console.log(state)
      if(!state.isAdmin) {
        renterList = renterList.filter(renter => renter.creator == state.loggedUserEmail)
      }
      console.log(renterList)
      commit('setRenters', renterList)
    },
    async firstFetch({dispatch}) {
      await dispatch('updateUsers');
      await dispatch('updateBMSs'); 
    },
    async updateMonitors({commit, state}) {
      let response = await this._vm.axios.get('https://4wq3fnef63.execute-api.eu-central-1.amazonaws.com/default/listMonitors')
      let monitorList = response.data.Items;
      console.log("Fetch monitors")
      console.log(state)
      if(!state.isAdmin) {
        monitorList = monitorList.filter(monitor => monitor.user == state.loggedUserEmail)
      }
      console.log(monitorList)
      commit('setMonitors', monitorList)
    },
    async fetchCustomization({commit, state}) {
      console.log("Fetching of initial customization")
      this._vm.axios.get(`https://rjl94x88s3.execute-api.eu-central-1.amazonaws.com/default/userCustomization?id=${state.loggedUserEmail}`).then((response) => {     
        console.log("Initial customization fetched")
        console.log(response)
        if(response.data) {
          if(response.data.image)
            commit('setTempLogo', response.data.image)
          else
            commit('setTempLogo', '')
          if(response.data.color)
            commit('setTempColor', response.data.color)
          else
            commit('setTempColor', "#3792cb")
          commit('saveCustomization')
        }     
      }).catch(error => {
        console.log("ERROR FETCHING CUSTOMIZATION")
        commit('setTempColor', "#3792cb")
        commit('saveCustomization')
        console.log(error)
      }); 
    }
  }
})
export default store

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data() {
    return {
      unsubscribeAuth: undefined
    }
  },
  methods: {
    fetchAlias: function () {
      /*
      fetch(aliasFile, {cache: "reload"})
        .then(response => response.text())
        .then(text => {
          const alias = parse(text)
          this.$store.commit('setAlias', alias)
        });
        */
    }
  },
  created: function () {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {     
      if(authState === 'signedin') {
        console.log("User signed in")
        store.commit('userLogin', authData.attributes)
        //store.dispatch('updateUsers')
        //store.dispatch('updateBMSs')
        // The first time users and bms are fetched, a race condition arises
        // updateBms can't complete if updateUsers is not completed first
        // firstFetch avoid the race awaiting the latter
        store.dispatch('firstFetch')
        store.dispatch('fetchCustomization')

        if(router.currentRoute.fullPath != '/recharge-room')
          router.push('/recharge-room')
      }
      else
      {
        console.log("User signed out")
        store.commit('userLogout')
      }    
      
    })
    // TODO remove this
    //this.$store.dispatch('updateUsers')
    //this.$store.dispatch('updateBMSs')
  },
  beforeDestroy: function(){
    this.unsubscribeAuth();
    clearInterval(this.interval)
  }
}).$mount("#app");

function hexToHsl(hexColor){

  var r = parseInt(hexColor.substr(1,2), 16);
  var g = parseInt(hexColor.substr(3,2), 16);
  var b = parseInt(hexColor.substr(5,2), 16);

  r /= 255, g /= 255, b /= 255;
  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;

  if(max == min){
      h = s = 0; // achromatic
  }else{
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch(max){
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
  }

  return [h, s, l];
}

function hueToColor(h) {
  var hue = h*360;
  if(hue >= 20 && hue < 40)
    return 'orange';
  if(hue >= 40 && hue < 80)
    return 'yellow';
  if(hue >= 80 && hue < 160)
    return 'green';
  if(hue >= 160 && hue < 200)
    return 'teal';
  if(hue >= 200 && hue < 270)
    return 'blue';
  if(hue >= 270 && hue < 300)
    return 'purple';
  if(hue >= 300 && hue < 330)
    return 'pink';
  else
    return 'red';
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : {};
}
